import _ from 'lodash';
import cx from 'classnames';
import { FormikErrors } from 'formik';
import { Form } from 'react-bootstrap';
import React, { ChangeEvent } from 'react';

import { FormGroup } from '@components';
import { BuyerFormValues } from '@types';
import { strIsLatin } from '@helpers/string';
import { AutoCompleteInput } from '@components/AutoCompleteInput';

interface Props {
  readOnly?: boolean;
  title?: string;
  withoutSuggest?: boolean;
  contentClassName?: string;
  values: BuyerFormValues;
  errors: FormikErrors<BuyerFormValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

const Address: React.FC<Props> = (props) => {
  const handleHasDifferentDeliveryAddressChange = (hasDifferentDeliveryAddress: boolean) => {
    props.setFieldValue('hasDifferentDeliveryAddress', hasDifferentDeliveryAddress);
  };

  const handleHasDifferentInvoiceAddressChange = (value: boolean) => {
    props.setFieldValue('hasDifferentInvoiceAddress', value);
  };

  const handleLatinValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      props.handleChange(e);
      return;
    }
    if (strIsLatin(e.target.value)) {
      props.handleChange(e);
    }
  };

  return (
    <>
      <div className="step-buyer-section">
        {!!props.title && <div className={cx('section-title')}>{props.title}</div>}
        <div className={props.contentClassName || 'responsive-table-content'}>
          {props.withoutSuggest ? (
            <FormGroup
              required
              type="text"
              autoComplete="off"
              name="address"
              id="user-address"
              label="Ulice a č.p."
              readOnly={props.readOnly}
              value={props.values.address}
              error={_.get(props.errors, 'userAddresses_0_address')}
              onChange={handleLatinValueChange}
            />
          ) : (
            <AutoCompleteInput
              inputId="user-address"
              placeHolder="Zvolte adresu..."
              onSelection={async (origData) => {
                const municipality: { type: string; name: string } | undefined = origData?.regionalStructure?.find(
                  (e: { type: string; name: string }) => e.type === 'regional.municipality'
                );
                props.setFieldValue('address', origData.name || '');
                props.setFieldValue('city', municipality?.name || '');
                props.setFieldValue('zip', origData.zip?.replaceAll(' ', '') || '');
              }}
            >
              <FormGroup
                required
                type="text"
                autoComplete="off"
                name="address"
                id="user-address"
                label="Ulice a č.p."
                readOnly={props.readOnly}
                value={props.values.address}
                error={_.get(props.errors, 'userAddresses_0_address')}
                onChange={handleLatinValueChange}
              />
            </AutoCompleteInput>
          )}
          <FormGroup
            required
            type="text"
            name="zip"
            label="PSČ"
            value={props.values.zip}
            readOnly={props.readOnly}
            error={_.get(props.errors, 'userAddresses_0_zipCode')}
            onChange={handleLatinValueChange}
          />
          <FormGroup
            required
            type="text"
            name="city"
            label="Město"
            value={props.values.city}
            readOnly={props.readOnly}
            error={_.get(props.errors, 'userAddresses_0_city')}
            onChange={handleLatinValueChange}
          />
          <FormGroup
            required
            type="text"
            name="country"
            label="Země"
            value={props.values.country}
            readOnly={props.readOnly}
            error={_.get(props.errors, 'userAddresses_0_country')}
            onChange={handleLatinValueChange}
          />
          <Form.Group className="d-flex align-items-center ml-3 mt-5">
            <Form.Check
              custom
              readOnly={props.readOnly}
              type="checkbox"
              label="Adresa pro doručování je jiná než adresa trvalého bydliště"
              className="mr-4"
              id="hasDifferentDeliveryAddress"
              name="hasDifferentDeliveryAddress"
              checked={props.values.hasDifferentDeliveryAddress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!props.readOnly) {
                  handleHasDifferentDeliveryAddressChange(e.target.checked);
                }
              }}
            />
          </Form.Group>
        </div>
      </div>
      {!!props.values.hasDifferentDeliveryAddress && (
        <div className="step-buyer-section mt-0">
          <div className={cx('section-title')}>Doručovací adresa</div>
          <div className={props.contentClassName || 'responsive-table-content'}>
            {props.withoutSuggest ? (
              <FormGroup
                required
                id="user-address-delivery"
                error={_.get(props.errors, 'userAddresses_1_address')}
                type="text"
                name="deliveryAddress"
                label="Ulice a č.p."
                readOnly={props.readOnly}
                value={props.values.deliveryAddress}
                onChange={handleLatinValueChange}
              />
            ) : (
              <AutoCompleteInput
                inputId="user-address-delivery"
                placeHolder="Zvolte adresu..."
                onSelection={async (origData) => {
                  const municipality: { type: string; name: string } | undefined = origData?.regionalStructure?.find(
                    (e: { type: string; name: string }) => e.type === 'regional.municipality'
                  );
                  props.setFieldValue('deliveryAddress', origData.name || '');
                  props.setFieldValue('deliveryCity', municipality?.name || '');
                  props.setFieldValue('deliveryZip', origData.zip?.replaceAll(' ', '') || '');
                }}
              >
                <FormGroup
                  required
                  id="user-address-delivery"
                  error={_.get(props.errors, 'userAddresses_1_address')}
                  type="text"
                  label="Ulice a č.p."
                  name="deliveryAddress"
                  readOnly={props.readOnly}
                  value={props.values.deliveryAddress}
                  onChange={handleLatinValueChange}
                />
              </AutoCompleteInput>
            )}
            <FormGroup
              required
              type="text"
              name="deliveryZip"
              label="PSČ"
              error={_.get(props.errors, 'userAddresses_1_zipCode')}
              value={props.values.deliveryZip}
              readOnly={props.readOnly}
              onChange={handleLatinValueChange}
            />
            <FormGroup
              required
              type="text"
              name="deliveryCity"
              label="Město"
              error={_.get(props.errors, 'userAddresses_1_city')}
              value={props.values.deliveryCity}
              readOnly={props.readOnly}
              onChange={handleLatinValueChange}
            />
            <FormGroup
              required
              type="text"
              name="deliveryCountry"
              label="Země"
              error={_.get(props.errors, 'userAddresses_1_country')}
              value={props.values.deliveryCountry}
              readOnly={props.readOnly}
              onChange={handleLatinValueChange}
            />
          </div>
        </div>
      )}

      <div className="step-buyer-section invoice-address-checkbox">
        <div className={props.contentClassName || 'responsive-table-content'}>
          <Form.Group className="d-flex align-items-center ml-3 mt-4">
            <Form.Check
              custom
              readOnly={props.readOnly}
              type="checkbox"
              label="Fakturační adresa je jiná než adresa trvalého bydliště"
              className="mr-4"
              id="hasDifferentInvoiceAddress"
              name="hasDifferentInvoiceAddress"
              checked={props.values.hasDifferentInvoiceAddress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!props.readOnly) {
                  handleHasDifferentInvoiceAddressChange(e.target.checked);
                }
              }}
            />
          </Form.Group>
        </div>
      </div>

      {!!props.values.hasDifferentInvoiceAddress && (
        <div className="step-buyer-section invoice-address-content mt-0">
          <div className={cx('section-title')}>Fakturační adresa</div>
          <div className={props.contentClassName || 'responsive-table-content'}>
            {props.withoutSuggest ? (
              <FormGroup
                required
                type="text"
                label="Ulice a č.p."
                name="invoiceAddress"
                id="user-address-invoice"
                readOnly={props.readOnly}
                error={_.get(
                  props.errors,
                  `userAddresses_${
                    props.values.hasDifferentDeliveryAddress && props.values.hasDifferentInvoiceAddress ? '2' : '1'
                  }_address`
                )}
                value={props.values.invoiceAddress}
                onChange={handleLatinValueChange}
              />
            ) : (
              <AutoCompleteInput
                inputId="user-address-invoice"
                placeHolder="Zvolte adresu..."
                onSelection={async (origData) => {
                  const municipality: { type: string; name: string } | undefined = origData?.regionalStructure?.find(
                    (e: { type: string; name: string }) => e.type === 'regional.municipality'
                  );
                  props.setFieldValue('invoiceAddress', origData.name || '');
                  props.setFieldValue('invoiceCity', municipality?.name || '');
                  props.setFieldValue('invoiceZip', origData.zip?.replaceAll(' ', '') || '');
                }}
              >
                <FormGroup
                  required
                  type="text"
                  label="Ulice a č.p."
                  name="invoiceAddress"
                  id="user-address-invoice"
                  readOnly={props.readOnly}
                  error={_.get(
                    props.errors,
                    `userAddresses_${
                      props.values.hasDifferentDeliveryAddress && props.values.hasDifferentInvoiceAddress ? '2' : '1'
                    }_address`
                  )}
                  value={props.values.invoiceAddress}
                  onChange={handleLatinValueChange}
                />
              </AutoCompleteInput>
            )}
            <FormGroup
              required
              type="text"
              name="invoiceZip"
              label="PSČ"
              error={_.get(
                props.errors,
                `userAddresses_${
                  props.values.hasDifferentDeliveryAddress && props.values.hasDifferentInvoiceAddress ? '2' : '1'
                }_zipCode`
              )}
              value={props.values.invoiceZip}
              readOnly={props.readOnly}
              onChange={handleLatinValueChange}
            />
            <FormGroup
              required
              type="text"
              name="invoiceCity"
              label="Město"
              error={_.get(
                props.errors,
                `userAddresses_${
                  props.values.hasDifferentDeliveryAddress && props.values.hasDifferentInvoiceAddress ? '2' : '1'
                }_city`
              )}
              value={props.values.invoiceCity}
              readOnly={props.readOnly}
              onChange={handleLatinValueChange}
            />
            <FormGroup
              required
              type="text"
              name="invoiceCountry"
              label="Země"
              error={_.get(
                props.errors,
                `userAddresses_${
                  props.values.hasDifferentDeliveryAddress && props.values.hasDifferentInvoiceAddress ? '2' : '1'
                }_country`
              )}
              value={props.values.invoiceCountry}
              readOnly={props.readOnly}
              onChange={handleLatinValueChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Address;
