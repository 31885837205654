import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import nl2br from 'react-nl2br';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

import icoCalendar from '@assets/images/front/auction-detail/ico-calendar.svg';
import icoAlertBell from '@assets/images/front/auction-detail/ico-alert-bell.svg';
import icoAlertBellRed from '@assets/images/front/auction-detail/ico-alert-bell-red.svg';
import icoHeartGreen from '@assets/images/ico-heart-green.svg';
import icoHeartGreyOutline from '@assets/images/ico-heart-grey-outline.svg';

import * as routes from '@routes';
import { useWindowFocus } from '@hooks';
import { useAuctionsApi } from '@api/auctions';
import { getCurrencyFormat } from '@helpers/formats';
import { getDateTimeFormat } from '@helpers/datetime';
import { useAuctionDetailWebsocket } from '@websocket';
import { useAuthFrontStore, usePageStore } from '@stores';
import { LoginModal, useVisibility, BasePreloader, SEO, Button } from '@components';
import {
  AccountAuctionsSections,
  AuctionImageType,
  AuctionInformedConsent,
  AuctionParameterResponse,
  AuctionResponse,
  UserRole,
} from '@types';
import BeforeJoinModal from '@components/FrontAuctionsList/BeforeJoinModal';
import AuctionTabs from './components/AuctionTabs';
import AuctionParams from './components/AuctionParams';
import AuctionImages from './components/AuctionImages';
import AuctionBottomBox from './components/AuctionBottomBox';
import FrontAuctionNotFound from '@components/FrontAuctionNotFound/FrontAuctionNotFound';
import AuctionCountdown from '@components/AuctionCountdown/AuctionCountdown';
import { InformedConsentsModal } from '@components/InformedConsentsModal/InformedConsentsModal';

interface ParamsType {
  id: string;
}

export enum AuctionTypeEnum {
  auction = 'auction',
  auction_public = 'auction_public',
}

type Props = {
  type: keyof typeof AuctionTypeEnum;
};

const FrontAuctionDetail: React.FC<Props> = (props) => {
  const auctionsApi = useAuctionsApi();
  const auctionsUserApi = useAuctionsApi(true);
  const pageState = usePageStore();
  const history = useHistory();
  const auctionTimeout = useRef<NodeJS.Timeout | undefined>();
  const tabsRef = useRef<null | HTMLDivElement>(null);
  const { id } = useParams<ParamsType>();
  const { isLogged } = useAuthFrontStore();
  const [loaded, setLoaded] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [auction, setAuction] = useState<AuctionResponse>();
  const [auctionParameterShort, setAuctionParameterShort] = useState<AuctionParameterResponse[]>();
  const [auctionParameterComplete, setAuctionParameterComplete] = useState<AuctionParameterResponse[]>();
  const [securityPaid, setSecurityPaid] = useState<boolean>(false);
  const [authorizationState, setAuthorizationState] = useState<string>('');
  const [auctionSecurity, setAuctionSecurity] = useState<string>('');
  const [auctionSecurityTranslation, setAuctionSecurityTranslation] = useState<string>('');
  const [admitted, setAdmitted] = useState<boolean>(false);
  const [preEmption, setPreEmption] = useState<boolean>(false);
  const [canDraw, setCanDraw] = useState<boolean>(false);
  const [hasActiveProtest, setHasActiveProtest] = useState<boolean>(false);
  const [uniqueCode, setUniqueCode] = useState('');
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [activeTabSection, setActiveTabSection] = useState(0);
  const [auctionImages, setAuctionImages] = useState<AuctionImageType[]>([]);
  const [showInformedConsents, setShowInformedConsents] = useState<AuctionInformedConsent[] | undefined>();
  const [beforeJoin, setBeforeJoin] = useState<{
    modalTitle?: string;
    content: React.ReactNode;
    actionText: string;
    onAction: () => void;
  } | null>(null);
  const { shouldBeVisible } = useVisibility();
  const enableJoinAuction = shouldBeVisible({
    isLoggedFront: true,
    role: UserRole.buyer,
    disableSuperAdminOverride: true,
  });
  const auctionWS = useAuctionDetailWebsocket({ id });

  useWindowFocus({
    onFocus: () => {
      loadAuction(false, auction?.parentStatus);
    },
  });

  useEffect(() => {
    if (!!auctionWS && !!auction) {
      loadAuction(false, auction?.parentStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auctionWS]);

  useEffect(() => {
    loadAuction(true);
    return () => {
      auctionsApi.cancelAllRequests();
      auctionsUserApi.cancelAllRequests();
      cancelAuctionTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged, id]);

  const cancelAuctionTimeout = () => {
    if (auctionTimeout.current) {
      clearTimeout(auctionTimeout.current);
    }
  };

  const loadUserAuction = async (auctionId: string | number, logged: boolean) => {
    if (!logged) {
      setSecurityPaid(false);
      setUniqueCode('');
      setAuthorizationState('');
      setAuctionSecurity('');
      setAuctionSecurityTranslation('');
      setAdmitted(false);
      setPreEmption(false);
      setCanDraw(false);
      return;
    }
    try {
      const response = await auctionsUserApi.getUserAuction(auctionId);
      const data = response.data.data;
      const informedConsents = data.auctionInformedConsents;

      // Show informed consents
      if (data.auction.parentStatus === 'ongoing' && data.admitted && informedConsents.some((ic) => !ic.approved)) {
        if (!showInformedConsents) {
          setShowInformedConsents(informedConsents);
        }
      } else {
        setShowInformedConsents(undefined);
      }

      setSecurityPaid(data.securityPaid);
      setUniqueCode(data.uniqueCode.toString());
      setAuthorizationState(data.authorizationState);
      setAuctionSecurity(data.auctionSecurity);
      setAuctionSecurityTranslation(data.auctionSecurityTranslation);
      setAdmitted(data.admitted);
      setPreEmption(data.preEmption || data.ownershipPreEmption);
      setCanDraw(data.canDraw);
      setHasActiveProtest(data.hasActiveProtest);
    } catch (err) {
      if (auctionsUserApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadAuctionParameterComplete = async () => {
    try {
      const response = await auctionsApi.auctionParameterComplete(id);
      setAuctionParameterComplete(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadAuctionParameterShort = async () => {
    try {
      const response = await auctionsApi.auctionParameterShort(id);
      setAuctionParameterShort(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadAuction = async (init: boolean, oldStatus?: string, data?: AuctionResponse) => {
    cancelAuctionTimeout();
    try {
      const currentData = data || (await auctionsApi.getWebAuctionInformations(id, props.type)).data.data;
      if (!!oldStatus && currentData.parentStatus === 'ongoing' && oldStatus !== currentData.parentStatus) {
        setActiveTabSection(4);
      }
      if (init && currentData.parentStatus === 'ongoing') {
        setActiveTabSection(4);
      }
      setAuction(currentData);
      loadAuctionParameterShort();
      loadAuctionParameterComplete();
      await loadUserAuction(currentData.id, isLogged);
      setNotFound(false);
      setLoaded(true);
      if (init) {
        setAuctionImages(currentData.auctionMedia);
      }
      auctionTimeout.current = setTimeout(() => loadAuction(false, currentData.parentStatus), 8000);
    } catch (err) {
      if (!err.response) {
        return;
      }
      if (err.response.status === 404) {
        setNotFound(true);
      }
      setLoaded(true);
    }
  };

  const handleAuctionJoin = async () => {
    try {
      await auctionsApi.joinAuction(id || '');
      history.push(`${pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS)}?s=${AccountAuctionsSections.prepared}`);
    } catch (err) {}
  };

  const handleAuctionIsFavouriteChange = async (e: React.MouseEvent) => {
    e.preventDefault();

    if (!isLogged) {
      setBeforeJoin({
        modalTitle: 'Přidat do oblíbených',
        content: 'Pro přidání do oblíbených je potřeba se přihlásit.',
        actionText: 'Přihlásit',
        onAction: () => {
          setBeforeJoin(null);
          setShowLoginModal(true);
        },
      });
      return;
    }

    try {
      if (!auction?.isFavorite) {
        await auctionsApi.addAuctionToFavourites(auction?.id || '');
      } else {
        await auctionsApi.removeAuctionFromFavourites(auction?.id || '');
      }
      loadAuction(false);
    } catch (err) {}
  };

  const handleJoinClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (enableJoinAuction) {
      handleAuctionJoin();
      return;
    }

    if (isLogged && !enableJoinAuction) {
      setBeforeJoin({
        content: 'Pro aktivní účast v dražbě/aukci je potřeba dokončit registraci dražitele.',
        actionText: 'Dokončit registraci dražitele',
        onAction: () => {
          setBeforeJoin(null);
          history.push(pageState.getPagePath(routes.front.ACCOUNT_AUCTIONEER_REGISTRATION));
        },
      });
      return;
    }

    setBeforeJoin({
      content: 'Pro aktivní účast v dražbě je potřeba se přihlásit.',
      actionText: 'Přihlásit',
      onAction: () => {
        setBeforeJoin(null);
        setShowLoginModal(true);
      },
    });
    return;
  };

  const handleMoreClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setActiveTabSection(1);
    if (!!tabsRef.current) {
      tabsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleOngoingClick = () => {
    setActiveTabSection(4);
    if (!!tabsRef.current) {
      tabsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const getOngoingText = () => {
    if (!auction) {
      return '';
    }

    if (auction.status !== 'ongoing' && auction.canProtest && auction.protestEndDatetime) {
      return auction.translations?.auction_protest_processing || 'auction_protest_processing';
    }

    if (auction.canProtest && auction.protestEndDatetime) {
      return `${auction.translations?.auction_can_protest_end || 'auction_can_protest_end'}: `;
    } else {
      return `${auction.translations?.auction_until_end || 'auction_until_end'}: `;
    }
  };

  const renderFavouriteAction = () => {
    if (!isLogged) {
      return null;
    }
    return (
      <a href="/" onClick={handleAuctionIsFavouriteChange} className="action-like">
        {auction?.isFavorite ? (
          <img src={icoHeartGreen} alt="favourite" />
        ) : (
          <img src={icoHeartGreyOutline} alt="favourite" />
        )}
      </a>
    );
  };

  const renderOngoingBox = () => {
    if (auction?.status === 'ongoing_outage' && auction?.outage) {
      return (
        <div className="block-alert alert-tab mb-2 mt-4">
          <p>{auction.translations?.auction_outage || 'auction_outage'}</p>
        </div>
      );
    }

    return (
      <>
        {auction &&
          auction.parentStatus === 'ongoing' &&
          (auction.draw && auction.drawToDt ? (
            <div className="block-alert">
              <p>
                Probíhá losování:{' '}
                <span className="notranslate">
                  <AuctionCountdown
                    completeText="Čekám výsledek"
                    date={moment(auction.drawToDt).toDate()}
                    onlySeconds
                  />
                </span>
                <br />
                Vyhrává nejvyšší vylosované číslo.
              </p>
            </div>
          ) : (
            <div className="block-alert">
              <p>
                <img src={icoAlertBell} alt="icon" />
                {getOngoingText()}
                <span className="notranslate">
                  {auction.canProtest && auction.protestEndDatetime ? (
                    <>
                      {auction.status === 'ongoing' && (
                        <AuctionCountdown
                          ongoing
                          completeText="Probíhá ukončování"
                          date={moment(auction.protestEndDatetime).toDate()}
                        />
                      )}
                    </>
                  ) : (
                    <AuctionCountdown
                      ongoing
                      completeText="Probíhá ukončování"
                      date={moment(auction.realEndDt || auction.endDt).toDate()}
                    />
                  )}
                </span>
              </p>
              <span
                className="alert-link text-d-none text-color-gray cursor-pointer"
                onClick={() => handleOngoingClick()}
              >
                {auction.translations?.auction_ongoing || 'auction_ongoing'}
              </span>
            </div>
          ))}
      </>
    );
  };

  if (!loaded) {
    return (
      <div className="component-front-auction-detail">
        <div className="auction-detail-content">
          <div className="d-flex align-items-center justify-content-center">
            <BasePreloader />
          </div>
        </div>
      </div>
    );
  }

  if (loaded && notFound) {
    return (
      <div className="component-auction-404">
        <FrontAuctionNotFound />
      </div>
    );
  }

  return (
    <>
      <SEO title={auction?.title} />
      <LoginModal
        title="Přihlášení"
        isOpen={showLoginModal}
        onRequestClose={() => setShowLoginModal(false)}
        onSuccess={() => setShowLoginModal(false)}
      />

      {!!showInformedConsents && auction?.id && (
        <InformedConsentsModal
          auctionId={auction.id}
          informedConsents={showInformedConsents}
          onSuccess={() => loadAuction(false, auction?.parentStatus)}
        />
      )}

      <BeforeJoinModal isOpen={!!beforeJoin} onRequestClose={() => setBeforeJoin(null)} {...(beforeJoin as any)} />
      <div className="component-front-auction-detail">
        <div className="auction-detail-content">
          {renderFavouriteAction()}

          <h1 className="auction-title">{auction?.title}</h1>

          {auction && auction.parentStatus === 'prepared' && auction.status !== 'adjourned' && (
            <div className="block-alert">
              <p>
                <img src={icoAlertBell} alt="icon" />
                {auction.startDt ? (
                  <>
                    {auction.translations?.auction_until_start || 'auction_until_start'}:{' '}
                    <span className="notranslate">
                      <AuctionCountdown
                        completeText="Probíhá zahajování"
                        date={moment(auction?.startDt).toDate()}
                        onComplete={() => setTimeout(() => loadAuction(false, auction.parentStatus), 1000)}
                      />
                    </span>
                  </>
                ) : (
                  `${auction.translations?.auction_not_set_start || 'auction_not_set_start'}`
                )}
              </p>
              {auction?.isJoined ? (
                <span className="alert-link text-d-none">Již jste připojen</span>
              ) : (
                <>
                  {!auction?.outage && (
                    <a href="/" className="alert-link" onClick={handleJoinClick}>
                      {auction.translations?.auction_join || 'auction_join'}
                    </a>
                  )}
                </>
              )}
            </div>
          )}

          {renderOngoingBox()}

          {auction &&
            (auction.parentStatus === 'ended' || auction.parentStatus === 'overcharge') &&
            auction.status !== 'ended_adjourned' &&
            auction.status !== 'ended_abandoned' &&
            auction.status !== 'adjourned' &&
            auction.status !== 'insolvency_proceedings' &&
            auction.status !== 'canceled' && (
              <div className="block-alert info-wrapper">
                <div className=" info-wrapper">
                  <p className="start-date ">
                    <img src={icoAlertBell} alt="icon" />
                    {auction.translations?.auction_started || 'auction_started'} {getDateTimeFormat(auction.startDt)}
                  </p>
                  <p className="end-date ">
                    <img src={icoAlertBellRed} alt="icon" />
                    {auction.translations?.auction_ended || 'auction_ended'}{' '}
                    {getDateTimeFormat(auction.realEndDt || auction.endDt, { withSeconds: !!auction.realEndDt })}
                  </p>
                </div>
              </div>
            )}

          <div className="top-info">
            <div className="price-label">{auction?.winnerBid?.bidPrice ? `Vydraženo za` : `Nejnižší podání`}:</div>
            <div className="price-value">{getCurrencyFormat(auction?.winnerBid?.bidPrice || auction?.minimalBid)}</div>
            <div className="auction-label">{auction?.statusTranslation}</div>
            <div className="top-info__right">
              <div className="auction-number">
                <span> {auction?.translations?.auction_number || 'auction_number'}:</span> {auction?.number}
              </div>
              <div className="auction-created">
                <span>Vloženo:</span> {getDateTimeFormat(auction?.timeCreated)}
              </div>
            </div>
          </div>

          <div className="block-auction">
            <Row>
              <Col sm={12} md={6} className="mb-4 mb-md-0 column-images">
                <AuctionImages images={auctionImages} />
                {!!auction?.visitationDescription && (
                  <div className="banner-info">
                    <img src={icoCalendar} alt="icon" className="block-ico" />
                    <div className="block-text">
                      <h3 className="item-title">Termín prohlídky:</h3>
                      <p className="item-text">{nl2br(auction?.visitationDescription)}</p>
                    </div>
                  </div>
                )}
              </Col>
              <Col sm={12} md={6} className="column-params">
                <AuctionParams
                  auction={auction}
                  onMoreClick={handleMoreClick}
                  parameterShort={auctionParameterShort}
                  parameterComplete={auctionParameterComplete}
                />

                <div className="text-center mt-2 auction-share-buttons">
                  <Button className="m-2" onClick={handleAuctionIsFavouriteChange}>
                    {auction?.isFavorite ? 'Odebrat z oblíbených' : 'Přidat do oblíbených'}
                  </Button>
                  <Button
                    externalTarget
                    target="_blank"
                    className="m-2"
                    to={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                  >
                    Sdílet na Facebook
                  </Button>
                  <Button
                    className="m-2"
                    onClick={() => {
                      if (!document.execCommand('print')) {
                        window.print();
                      }
                    }}
                  >
                    Vytisknout
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="block-tabs" ref={tabsRef}>
          <AuctionTabs
            tabsRef={tabsRef}
            auctionDetail={auction as AuctionResponse}
            activeSection={activeTabSection}
            onChange={setActiveTabSection}
            authorizationState={authorizationState}
            uniqueCode={uniqueCode}
            admitted={admitted}
            preEmption={preEmption}
            canDraw={canDraw}
            hasActiveProtest={hasActiveProtest}
            onJoinClick={handleJoinClick}
            auctionParameterComplete={auctionParameterComplete}
            auctionReload={() => loadAuction(false, auction?.parentStatus)}
          />
        </div>

        <div className="block-bottom-box">
          <AuctionBottomBox
            auction={auction}
            securityPaid={securityPaid}
            authorizationState={authorizationState}
            auctionSecurity={auctionSecurity}
            auctionSecurityTranslation={auctionSecurityTranslation}
            admitted={admitted}
          />
        </div>
      </div>
    </>
  );
};

export default FrontAuctionDetail;
