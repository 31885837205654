import React, { ReactNode, useEffect, useRef } from 'react';
import autoComplete from '@tarekraafat/autocomplete.js/dist/autoComplete.js';

import config from '@config';

interface AutoCompleteInputProps {
  inputId: string;
  placeHolder: string;
  onSelection: (data: any) => Promise<void>;
  children: ReactNode;
}

export const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({
  inputId,
  placeHolder,
  onSelection,
  children,
}) => {
  const addressInputRef = useRef<HTMLInputElement | null>(null);

  const getItems = async (query: string) => {
    try {
      const fetchData = await fetch(
        `https://api.mapy.cz/v1/suggest?lang=cs&limit=5&type=regional.address,coordinate&apikey=${config.mapyApiKey}&query=${query}`
      );
      const jsonData = await fetchData.json();

      const items = jsonData.items.map((item: any) => ({
        value: item.name,
        data: item,
      }));
      return items;
    } catch (err) {
      console.error('Failed to fetch suggestions', err);
      return [];
    }
  };

  useEffect(() => {
    const initAutoComplete = () => {
      const autocompleteElement = addressInputRef.current;

      if (autocompleteElement) {
        const autoCompleteJS = new autoComplete({
          selector: () => autocompleteElement,
          placeHolder,
          searchEngine: (query: any, record: any) => `<mark>${record}</mark>`,
          data: {
            keys: ['value'],
            src: async (query: string) => {
              return await getItems(query); // Assuming getItems is a data-fetching function
            },
          },
          resultItem: {
            element: (item: any, data: any) => {
              const itemData = data.value.data;
              const desc = document.createElement('div');
              desc.style.overflow = 'hidden';
              desc.style.whiteSpace = 'nowrap';
              desc.style.textOverflow = 'ellipsis';
              desc.innerHTML = `${itemData.label}, ${itemData.location}`;
              item.appendChild(desc);
            },
            highlight: true,
          },
          resultsList: {
            element: (list: any, data: any) => {
              list.style.maxHeight = 'max-content';
              list.style.overflow = 'hidden';

              if (!data.results.length) {
                const message = document.createElement('div');
                message.setAttribute('class', 'no_result');
                message.style.padding = '5px';
                message.innerHTML = `<span>No results found for "${data.query}".</span>`;
                list.prepend(message);
              } else {
                const logoHolder = document.createElement('div');
                const text = document.createElement('span');
                const img = new Image();

                logoHolder.style.padding = '5px';
                logoHolder.style.display = 'flex';
                logoHolder.style.alignItems = 'center';
                logoHolder.style.justifyContent = 'end';
                logoHolder.style.gap = '5px';
                logoHolder.style.fontSize = '12px';
                text.textContent = 'Powered by';
                img.src = 'https://api.mapy.cz/img/api/logo-small.svg';
                img.style.width = '60px';
                logoHolder.append(text, img);
                list.append(logoHolder);
              }
            },
            noResults: true,
          },
        });

        // Event listener for selection
        autocompleteElement.addEventListener('selection', async (event: any) => {
          const origData = event.detail.selection.value.data;
          await onSelection(origData); // Call the provided handler with the selected data
        });

        return () => {
          autoCompleteJS.unInit();
        };
      }
    };

    // Initialize autocomplete after input is available
    const inputElement = document.getElementById(inputId) as HTMLInputElement;
    if (inputElement) {
      addressInputRef.current = inputElement;
      initAutoComplete();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>{children}</div>;
};
