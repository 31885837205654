import clx from 'classnames';
import _, { isArray } from 'lodash';
import moment from 'moment/moment';
import { FormikErrors } from 'formik';
import React, { ChangeEvent } from 'react';

import { Form } from 'react-bootstrap';
import { strIsLatin } from '@helpers/string';
import { getRequestDateFormat } from '@helpers/datetime';
import { AutoCompleteInput } from '@components/AutoCompleteInput';
import { BuyerFormValues, UserAdditionalPersonValues } from '@types';
import { Button, ControlFeedback, DatePickerInput, FormGroup } from '@components';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';

interface Props {
  itemIndex: number;
  readOnly?: boolean;
  title?: string;
  withoutSuggest?: boolean;
  contentClassName?: string;
  values: BuyerFormValues;
  errors: FormikErrors<BuyerFormValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

const Partner: React.FC<Props> = (props) => {
  const handleLatinValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      props.handleChange(e);
      return;
    }
    if (strIsLatin(e.target.value)) {
      props.handleChange(e);
    }
  };

  const handleItemRemove = () => {
    let items: UserAdditionalPersonValues[] = [];
    (props.values.userAdditionalsPerson || []).forEach((item, index) => {
      if (index !== props.itemIndex) {
        items.push(item);
      }
    });
    props.setFieldValue('userAdditionalsPerson', items);
  };

  const handleBirthDateChange = (values: Date | Array<Date> | null) => {
    if (values !== null) {
      const value = isArray(values) ? values[0] : values;
      props.setFieldValue(`userAdditionalsPerson.${props.itemIndex}.birthdate`, getRequestDateFormat(value));
    }
  };

  return (
    <div className={clx([props.contentClassName || 'responsive-table-content', 'mt-4', 'mb-2'])}>
      <FormGroup
        required
        type="text"
        label="Jméno"
        readOnly={props.readOnly}
        name={`userAdditionalsPerson.${props.itemIndex}.firstName`}
        error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_firstName`)}
        value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.firstName`)}
        onChange={handleLatinValueChange}
      />
      <FormGroup
        required
        type="text"
        label="Příjmení"
        readOnly={props.readOnly}
        name={`userAdditionalsPerson.${props.itemIndex}.lastName`}
        error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_lastName`)}
        value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.lastName`)}
        onChange={handleLatinValueChange}
      />
      <AutoCompleteInput
        inputId={`user-additional-person-address-${props.itemIndex}`}
        placeHolder="Zvolte adresu..."
        onSelection={async (origData) => {
          const municipality: { type: string; name: string } | undefined = origData?.regionalStructure?.find(
            (e: { type: string; name: string }) => e.type === 'regional.municipality'
          );
          props.setFieldValue(`userAdditionalsPerson.${props.itemIndex}.address`, origData.name || '');
          props.setFieldValue(`userAdditionalsPerson.${props.itemIndex}.city`, municipality?.name || '');
          props.setFieldValue(
            `userAdditionalsPerson.${props.itemIndex}.zipCode`,
            origData.zip?.replaceAll(' ', '') || ''
          );
        }}
      >
        <FormGroup
          required
          type="text"
          autoComplete="off"
          label="Ulice a č.p."
          readOnly={props.readOnly}
          id={`user-additional-person-address-${props.itemIndex}`}
          name={`userAdditionalsPerson.${props.itemIndex}.address`}
          error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_address`)}
          value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.address`)}
          onChange={handleLatinValueChange}
        />
      </AutoCompleteInput>
      <FormGroup
        required
        type="text"
        label="PSČ"
        readOnly={props.readOnly}
        name={`userAdditionalsPerson.${props.itemIndex}.zipCode`}
        error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_zipCode`)}
        value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.zipCode`)}
        onChange={handleLatinValueChange}
      />
      <FormGroup
        required
        type="text"
        label="Město"
        readOnly={props.readOnly}
        name={`userAdditionalsPerson.${props.itemIndex}.city`}
        error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_city`)}
        value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.city`)}
        onChange={handleLatinValueChange}
      />
      <FormGroup
        required
        type="text"
        label="Země"
        readOnly={props.readOnly}
        name={`userAdditionalsPerson.${props.itemIndex}.country`}
        error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_country`)}
        value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.country`)}
        onChange={handleLatinValueChange}
      />
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-right">Datum narození *</Form.Label>
        <div className="f-inline-control d-flex align-items-center">
          <Form.Group className="mb-2 inline-label mr-3 input-ico input-ico-md">
            <div
              className="w-135 position-relative"
              data-test-id={`user-registration-user-additionals-person-${props.itemIndex}-birthdate`}
            >
              <DatePickerInput
                clearIcon={null}
                readOnly={props.readOnly}
                onChange={handleBirthDateChange}
                calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                value={
                  !!_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.birthdate`)
                    ? moment(_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.birthdate`)).toDate()
                    : undefined
                }
                className={clx([
                  'form-control',
                  { 'is-invalid': !!_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_birthdate`) },
                ])}
              />
              {!!_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_birthdate`) && (
                <ControlFeedback type="invalid" style={{ left: 0, bottom: -25 }}>
                  {_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_birthdate`)}
                </ControlFeedback>
              )}
            </div>
          </Form.Group>
        </div>
      </Form.Group>
      <FormGroup
        required
        type="text"
        label="Rodné číslo"
        readOnly={props.readOnly}
        name={`userAdditionalsPerson.${props.itemIndex}.personalIdentificationNumber`}
        error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_personalIdentificationNumber`)}
        value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.personalIdentificationNumber`)}
        onChange={handleLatinValueChange}
      />
      <FormGroup
        required
        type="text"
        readOnly={props.readOnly}
        label="Číslo dokladu totožnosti"
        name={`userAdditionalsPerson.${props.itemIndex}.identityCardNumber`}
        error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_identityCardNumber`)}
        value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.identityCardNumber`)}
        onChange={handleLatinValueChange}
      />

      {props.values.type !== 'joint_ownership' && (
        <div style={{ minWidth: 750 }}>
          <FormGroup
            required
            type="number"
            label="Podíl"
            helpIcon="%"
            readOnly={props.readOnly}
            name={`userAdditionalsPerson.${props.itemIndex}.share`}
            error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_share`)}
            value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.share`)}
            onChange={handleLatinValueChange}
          />
        </div>
      )}

      {props.values.type !== 'joint_ownership' && (
        <div className="mb-4">
          <Button className="btn-sm pl-3 pr-3" onClick={handleItemRemove}>
            Odebrat spoluvlastníka
          </Button>
        </div>
      )}
    </div>
  );
};

export default Partner;
