import React, { useCallback, useEffect, useState } from 'react';
import L from 'leaflet';
import { get } from 'lodash';
import clx from 'classnames';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { ToastProvider } from 'react-toast-notifications';
import { useHistory, useLocation } from 'react-router-dom';

import config from '@config';
import { AuctionMapResponse, LatLng } from '@types';
import MapPopup from '@components/MapComponent/MapPopup';

import mapPointImage from '@assets/images/map-point.svg';
import 'leaflet.markercluster';

interface Props {
  className?: string;
  zoom?: number;
  height?: number;
  auctions?: AuctionMapResponse[];
  displayDetail?: boolean;
  pin?: LatLng;
  colors?: boolean;
}

const initialCenter: L.LatLngExpression = [49.8037633, 15.4749126];

const MapComponent: React.FC<Props> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [mapInstance, setMapInstance] = useState<any>();
  const [markerLayer, setMarkerLayer] = useState<any>();
  const [markerCluster, setMarkerCluster] = useState<any>();
  const [currentPin, setCurrentPin] = useState<LatLng | undefined>();

  const mapCallback = useCallback(async (node: HTMLDivElement) => {
    if (!node) {
      return;
    }

    const _mapInstance = L.map(node, { scrollWheelZoom: false }).setView(initialCenter, getInitialZoom());
    L.tileLayer(`https://api.mapy.cz/v1/maptiles/basic/256/{z}/{x}/{y}?apikey=${config.mapyApiKey}`, {
      attribution: '<a href="https://api.mapy.cz/copyright" target="_blank">&copy; Seznam.cz a.s. a další</a>',
      maxZoom: 18,
    }).addTo(_mapInstance);

    const LogoControl = L.Control.extend({
      options: {
        position: 'bottomleft',
      },

      onAdd: function () {
        const container = L.DomUtil.create('div');
        const link = L.DomUtil.create('a', '', container);

        link.setAttribute('href', 'http://mapy.cz/');
        link.setAttribute('target', '_blank');
        link.innerHTML = '<img alt="logo" src="https://api.mapy.cz/img/api/logo.svg" />';
        L.DomEvent.disableClickPropagation(link);

        return container;
      },
    });

    new LogoControl().addTo(_mapInstance);

    const _markerLayer = L.layerGroup().addTo(_mapInstance);
    const _markerCluster = L.markerClusterGroup().addTo(_mapInstance);

    setMapInstance(_mapInstance);
    setMarkerLayer(_markerLayer);
    setMarkerCluster(_markerCluster);

    _mapInstance.addEventListener('popupopen', (e) => {
      if (e.popup.getContent()) {
        return;
      }
      e.popup.close();
      const options = e?.popup?.options as { lazyload: React.ReactElement };
      if (options?.lazyload) {
        const popupElement = document.createElement('div');
        popupElement.className = 'map-popup-content-inner';
        e.popup.setContent(popupElement);
        ReactDOM.render(options.lazyload, popupElement, () => {
          setTimeout(() => {
            e.popup.openOn(_mapInstance);
          }, 200);
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!mapInstance) {
      const mapCenter: L.LatLngExpression = props.pin ? [props.pin.lat, props.pin.lng] : initialCenter;
      mapInstance.setView(mapCenter);
      mapInstance.setZoom(getInitialZoom());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInstance, location.search, location.pathname]);

  useEffect(() => {
    return () => {
      if (mapInstance) {
        mapInstance.off();
        mapInstance.remove();
        setMapInstance(null);
      }
    };
  }, [mapInstance]);

  React.useEffect(() => {
    if (!mapInstance || !markerLayer || !markerCluster) {
      return;
    }

    if (!props.pin && markerLayer) {
      markerLayer.clearLayers();
    }

    if (!!props.auctions && !!props.auctions.length) {
      setPoints(props.auctions);
    }

    if (!!props.pin && !(props.pin.lng === currentPin?.lng && props.pin.lat === currentPin?.lat)) {
      markerLayer.clearLayers();
      setPoint(props.pin);
    }
    setCurrentPin(props.pin);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInstance, markerLayer, markerCluster, props.auctions, props.pin]);

  const getInitialZoom = () => {
    const zoom = props.zoom || 7;
    return zoom <= 18 ? zoom : 18;
  };

  const setPoint = (point: LatLng) => {
    const markerCenter: L.LatLngExpression = [point.lat, point.lng];
    const markerIcon = L.icon({
      iconUrl: mapPointImage,
      iconSize: [40, 40],
      iconAnchor: [20, 40],
    });
    const marker = L.marker(markerCenter, { icon: markerIcon });
    markerCluster.addLayer(marker);
  };

  const setPoints = (auctions: AuctionMapResponse[]) => {
    if (!markerCluster) {
      console.error('Marker cluster is not initialized');
      return;
    }

    // Clear existing markers in case of re-setting points
    markerCluster.clearLayers();

    auctions.forEach((auction) => {
      const markerCenter: L.LatLngExpression = [auction.latitude, auction.longitude];

      const markerIcon = L.icon({
        iconUrl: mapPointImage,
        iconSize: [40, 40],
        iconAnchor: [20, 40],
      });

      const marker = L.marker(markerCenter, { icon: markerIcon });

      const popupContent = (
        <ToastProvider>
          <MapPopup
            history={history}
            id={auction.auction}
            title={auction.title}
            number={auction.number}
            deposit={auction.cautionDeposit}
            auctionType={auction.auctionType}
            city={auction.city}
            translations={auction.translations}
            category={auction.auctionCategoryTitle}
            typeTranslation={auction.auctionTypeTranslation}
            region={auction.region}
            parentCategory={auction.auctionCategoryParentTitle || ''}
            image={auction.mediaHash}
          />
        </ToastProvider>
      );

      marker.bindPopup(
        L.popup({
          lazyload: popupContent,
        } as L.PopupOptions & { lazyload: React.ReactElement })
      );
      markerCluster.addLayer(marker);
    });

    mapInstance.addLayer(markerCluster);
  };

  return (
    <div className={classNames(['component-map', props.className])}>
      <div
        ref={mapCallback}
        className={clx(['map', { bw: !props.colors }])}
        style={{ height: get(props, 'height', 500) }}
      />
    </div>
  );
};

export default MapComponent;
