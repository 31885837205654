import React, { useEffect } from 'react';
import { get } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useQueryParams, withDefault, ArrayParam, StringParam, QueryParamConfig, NumberParam } from 'use-query-params';

import alertIco from '@assets/images/front/account/alert.svg';

import { useAuthApi } from '@api/auth';
import { useAuctionsApi } from '@api/auctions';
import { AuctionMapResponse, AuctionResponse, WebAuctionsFilter } from '@types';
import { authFrontStore, useAuthFrontStore } from '@stores';
import { FrontActionsList, FrontMainFilter, Pagination } from '@components';
import { useAuctionsListWebsocket } from '@websocket';
import { useWindowFocus } from '@hooks';

const translate: any = {
  prepared: 'připravované',
  ongoing: 'probíhající',
  ended: 'ukončené',
  ended_overcharge: 'Předražky',
};

interface Props {
  status: string;
}

const FrontAuctionList: React.FC<Props> = ({ status }) => {
  const authApi = useAuthApi();
  const location = useLocation();
  const auctionsApi = useAuctionsApi();
  const authFrontState = useAuthFrontStore();
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [auctions, setAuctions] = React.useState<Array<AuctionResponse>>([]);
  const [allAuctions, setAllAuctions] = React.useState<AuctionMapResponse[]>([]);
  const [totalAuctions, setTotalAuctions] = React.useState<number>(0);
  const [showFiltersPreloader, setShowFiltersPreloader] = React.useState(true);
  const [joinedLoaded, setJoinedLoaded] = React.useState<string | number | undefined>();
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    perPage: withDefault(NumberParam, 15),
    title: withDefault(StringParam, ''),
    priceTo: withDefault(StringParam, ''),
    priceFrom: withDefault(StringParam, ''),
    mainCategory: withDefault(StringParam, '0'),
    auctionType: withDefault(StringParam, ''),
    subCategories: withDefault(ArrayParam, []) as QueryParamConfig<string[]>,
    regions: withDefault(ArrayParam, []) as QueryParamConfig<string[]>,
    districts: withDefault(ArrayParam, []) as QueryParamConfig<string[]>,
    districtsParts: withDefault(ArrayParam, []) as QueryParamConfig<string[]>,
  });
  useAuctionsListWebsocket({
    onMessage: () => {
      loadAuctions({ ...query });
      loadAllAuctions({ ...query }, true);
    },
  });

  useWindowFocus({
    onFocus: () => {
      loadAuctions({ ...query });
      loadAllAuctions({ ...query }, true);
    },
  });

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, [status]);

  useEffect(() => {
    setShowFiltersPreloader(true);
    setLoaded(false);
    loadAuctions(query);
    if (status !== 'ended') {
      loadAllAuctions(query);
    }
    return () => {
      auctionsApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authFrontState.user, location.search, status]);

  const loadAuctions = async (filterProps?: WebAuctionsFilter) => {
    if (authFrontStore.getState().isLogged) {
      try {
        await authApi.logged(true);
      } catch (err) {}
    }
    try {
      const requestProps: WebAuctionsFilter = !!filterProps ? { ...filterProps } : { page: 1, perPage: query.perPage };
      requestProps.mainCategory = requestProps.mainCategory !== '0' ? requestProps.mainCategory : undefined;
      requestProps.perPage = requestProps.perPage || query.perPage;
      requestProps.status = status;
      const response = await auctionsApi.getWebAuctions(
        { ...requestProps },
        status === 'ended' ? 'auction.realEndDt' : 'auction.startDt',
        status === 'ended' ? 'DESC' : 'ASC',
        'getAuctionsCancel'
      );
      setAuctions(response.data.data);
      setTotalAuctions(response.data?.total as number);
      setLoaded(true);
      if (status === 'ended') {
        setShowFiltersPreloader(false);
      }
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      setLoaded(true);
    }
  };

  const loadAllAuctions = async (filterProps?: WebAuctionsFilter, isTimer?: boolean) => {
    if (!isTimer) {
      setShowFiltersPreloader(true);
    }
    try {
      const requestProps: WebAuctionsFilter = !!filterProps ? { ...filterProps } : { page: 1, perPage: query.perPage };
      requestProps.mainCategory = requestProps.mainCategory !== '0' ? requestProps.mainCategory : undefined;
      requestProps.status = status;
      const responseAll = await auctionsApi.getWebMapAuctions({ ...requestProps });
      setAllAuctions(responseAll.data.data);
      setShowFiltersPreloader(false);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      setShowFiltersPreloader(false);
    }
  };

  const handleAuctionJoin = async (auction: AuctionResponse) => {
    if (!!joinedLoaded) {
      return;
    }
    setJoinedLoaded(auction.id);
    try {
      await auctionsApi.joinAuction(auction.id);
      setJoinedLoaded(undefined);
      loadAuctions(query);
      loadAllAuctions(query);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      setJoinedLoaded(undefined);
    }
  };

  const handleAuctionIsFavouriteChange = async (auction: AuctionResponse, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await auctionsApi.addAuctionToFavourites(auction.id);
      } else {
        await auctionsApi.removeAuctionFromFavourites(auction.id);
      }
      loadAuctions(query);
      loadAllAuctions(query);
    } catch (err) {}
  };

  return (
    <>
      <h1 className="auctions-page-title">{`${get<string>(translate, status, '')}`} dražby / aukce</h1>
      <FrontMainFilter
        hasToggler
        closeWithSubmit
        query={query}
        status={status}
        auctions={allAuctions}
        auctionsLoaded={loaded}
        itemsCount={totalAuctions}
        withMap={status !== 'ended'}
        showPreloader={showFiltersPreloader}
        onSearch={(props) => setQuery(props, 'push')}
      />
      {loaded && !showFiltersPreloader && !auctions.length && (
        <div className="auctions-empty">
          <img src={alertIco} alt="ico" className="item-ico" />
          Tomuto dotazu neodpovídají žádné {`${translate[status as string] || ''}`} dražby
        </div>
      )}
      <FrontActionsList
        auctions={auctions}
        isLoading={!loaded}
        joinedLoaded={joinedLoaded}
        onAuctionJoin={handleAuctionJoin}
        onAuctionIsFavouriteChange={handleAuctionIsFavouriteChange}
      />
      {loaded && !showFiltersPreloader && totalAuctions > query.perPage && (
        <div className="mt-2">
          <Pagination
            page={query.page}
            perPage={query.perPage}
            totalRows={totalAuctions}
            onPageChange={(page) => {
              setQuery({ ...query, page: page }, 'push');
              window?.scrollTo(0, 0);
            }}
          />
        </div>
      )}
    </>
  );
};

export default FrontAuctionList;
